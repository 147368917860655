import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Index from "./components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<Index />} />
          <Route path="/privacy" exact element={<PrivacyPolicy />} />
          <Route path="/terms" exact element={<TermsOfUse />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
