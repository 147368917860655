import React, { useLayoutEffect } from "react";
import "./terms.css";
import Logo from "../components/assets/img/logo.png";
import PrivacyHeader from "./PrivacyHeader";
import PrivacyContent from "./PrivacyContent";
import { useLocation } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <Wrapper>
      <div className="py-5 bg-light service-25" style={{ height: "100%" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12  mb-3">
              <img src={Logo} className="img-fluid" alt="logo" style={{ width: 150 }} />
            </div>
            <div className="col-md-12 ">
              <h1 className="my-3">Privacy Policy</h1>
              <hr />
              <p className="subtitle font-weight-light">Last updated "Last updated: Feb 02, 2023."</p>
              <PrivacyContent>
                We respect your privacy and value the relationship we have with you. This Privacy Policy is designed to
                show you what information about you we collect through your use of our apps.
              </PrivacyContent>
              <PrivacyHeader>Information We May Collect</PrivacyHeader>
              <PrivacyContent>
                We do not collect any personally identifiable information about you during your use of our apps, unless
                you choose to share it with us via a "Send feedback / Report a bug" option or sign up for our
                newsletter, as described in the appropriate sections below.
              </PrivacyContent>
              <PrivacyContent>
                The information associated with your media, such as the location where you took them, the capture date
                or the media itself, is stored in your device and not transmitted to our servers. When you use the
                sharing options in our apps to share your media, you do so directly with the destination service - be it
                email, facebook, twitter or any other third-party service or app. Your media and the metadata associated
                with them are never transferred to our servers, not even for sharing purposes. Again, the sole exception
                is when you choose to attach your media to a bug report for diagnostic purposes.
              </PrivacyContent>
              <PrivacyContent>
                We may collect anonymous information about your usage of our apps, with the purpose of improving them.
                We may store the sections you visit when you use our apps, so that we better understand our customers.
                We use this information in an aggregated manner to identify features that need improvement. No
                personally identifiable information is collected during this process. Analytics are subject to the
                Privacy Policy of Google Firebase and Amplitude.
              </PrivacyContent>
              <PrivacyHeader>Feedback and bug reporting</PrivacyHeader>
              <PrivacyContent>
                Our apps may provide an option called "Send feedback / Report a bug" that you can use to inform us of
                any bugs you observe, provide criticism, or simply share your thoughts with us. If you use this option,
                we ask for your email address so that we have a way to respond. You can optionally give us your name and
                a description of the issue you want to report.
              </PrivacyContent>
              <PrivacyContent>
                When you use this option we also gather some additional, non-personal information about your device and
                the state of our app to help us diagnose the issue you are reporting. This information includes:
                <ul>
                  <li>
                    Environment Info: Device model, iOS version, iOS language, free disk space, app version and number
                    of media present in the app's database.
                  </li>
                  <li>The app's settings: quality & saving options, camera settings used.</li>
                  <li>Description about the last error encountered, if any, as provided by the system.</li>
                </ul>
              </PrivacyContent>
              <PrivacyContent>
                We may update the information we gather in this manner when we release new features or identify areas
                that need better diagnosing. We will only use technical details about your device or our apps, and we
                will never include any personally identifiable information about you or your device. In particular, no
                device identifiers or IP addresses are or will be recorded.
              </PrivacyContent>
              <PrivacyContent>
                When some specific errors occur, such as when a photo cannot be saved, we offer you the possibility to
                send that particular file to us, along with any edits you may have applied to it. If you choose to
                attach the file, all the metadata information it contains will also be sent to us.
              </PrivacyContent>
              <PrivacyHeader>Crash reporting</PrivacyHeader>
              <PrivacyContent>
                When a crash occurs, you are given the option to send the crash report to us to help diagnose the
                problem. A crash report follows a standard format created by Apple. It contains technical information
                about your device, and may contain information about other software you have installed. No personally
                identifiable information is collected.
              </PrivacyContent>
              <PrivacyContent>
                Note that if you choose to "Always send" your crash reports, any subsequent crashes will be
                automatically sent without further confirmation.
              </PrivacyContent>
              <PrivacyHeader>Usage of the information we collect</PrivacyHeader>
              <PrivacyContent>
                The information you send when you use the bug or crash reporting options is only used for the purposes
                of identifying and correcting the problem you reported. The information is not used in any other way,
                profiling or otherwise; and it is not used for advertising purposes. That includes your email address,
                which will only be used to communicate with you regarding the problem you reported and will not be used
                for any other purpose.
              </PrivacyContent>
              <PrivacyContent>
                If you subscribe to our newsletter, the email address you provide will be used only for the purpose of
                delivering the newsletter messages. Your email address will not be used in any other way, or linked to
                the email address you may provide when you report a bug.
              </PrivacyContent>
              <PrivacyHeader>Opt-in Newsletter</PrivacyHeader>
              <PrivacyContent>
                Our apps, our website, and social media pages provide the option to sign up for an email newsletter,
                intended to provide useful information about our apps to existing or potential customers. The newsletter
                may include photography tips, periodic photographic challenges, links to tutorials or webinars. We may
                also request feedback via quizzes or other methods in order to better understand our customers, as a
                collective. If you choose to sign up for the newsletter, we will ask for an email address to send it to.
                If you respond to quizzes or engage in any type of feedback activity, we will use the answers you
                provide in an aggregated manner, and will not link your answers to your email address, originating IP or
                any other piece of personally identifiable information.
              </PrivacyContent>
              <PrivacyContent>
                Every newsletter message we send includes a link to unsubscribe. If you use it, we will delete your
                email address from our database.
              </PrivacyContent>
              <PrivacyHeader>Information we share with others</PrivacyHeader>

              <PrivacyContent>
                We may share your information under the following circumstances:
                <ul>
                  <li>
                    When we engage third-party services for specific purposes. The messages you send through the "Send
                    feedback / Report a bug" option are routed through a third-party service that we use for managing
                    customer communications. The service we currently use is Snappy (https://besnappy.com/){" "}
                  </li>
                  <li>
                    Your crash reports are sent to Visual Studio App Center (https://appcenter.ms/) for the purposes of
                    collating problems and symbolicating reports.
                  </li>
                  <li>
                    If you sign up for our newsletter, the email you provide will be sent to mailer lite
                    (https://www.mailerlite.com/), which we use for email delivery and scheduling.
                  </li>
                  <li>
                    As required by Law. We may disclose any of your information we deem necessary, in our sole
                    discretion, to comply with any applicable law, regulation, legal process or governmental request.
                  </li>
                  <li>
                    To Prevent Harm or Loss: We may disclose your information when we believe, in our sole discretion,
                    disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection
                    with suspected or actual illegal activity.
                  </li>
                </ul>
              </PrivacyContent>

              <PrivacyHeader>How We Store Your Information</PrivacyHeader>

              <PrivacyContent>
                The information you send through the "Send feedback / Report a bug" menu option is preserved, as
                required by law, for up to one year.
                <br />
                <br />
                If you sign up for our newsletter, your email address will be stored for as long as you are subscribed,
                and it will be deleted when you unsubscribe.
                <br />
                <br />
                We maintain administrative, technical and physical safeguards designed to protect your information that
                we collect about you against accidental, unlawful or unauthorized destruction, loss, alteration, access,
                disclosure or use. While we cannot guarantee that no accidental, unlawful or unauthorized destruction,
                loss, alteration, access, disclosure or use of your information will occur, we try to prevent such
                occurrences.
              </PrivacyContent>
              <PrivacyHeader>Rectification and erasure rights</PrivacyHeader>
              <PrivacyContent>
                You can exercise your rights to rectification and erasure by writing to us either from within our apps,
                or via email at websupport@latenitesoft.com.
              </PrivacyContent>
              <PrivacyHeader>Updates to This Privacy Policy</PrivacyHeader>
              <PrivacyContent>
                This Privacy Policy may be updated periodically and without prior notice to you to reflect changes in
                our information processing practices or for any other purpose. We will indicate at the top of the
                Privacy Policy when it was most recently updated.
              </PrivacyContent>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
