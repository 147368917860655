import React from "react";
import Logo from "./assets/img/logo.png";

export default function Header() {
  return (
    <header id="header" class="fixed-top d-flex align-items-center header-transparent">
      <div class="container d-flex align-items-center justify-content-between">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <a class="nav-link scrollto active" href="#hero">
                Product
              </a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#about">
                About
              </a>
            </li>

            {/* <li>
                  <a class="nav-link scrollto" href="#team">
                    Team
                  </a>
                </li> */}

            <li>
              <a class="nav-link scrollto" href="#contact">
                Contact
              </a>
            </li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
}
