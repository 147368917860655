import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer id="footer">
      <div class="container py-4">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span> Rekoda</span>
          </strong>
          . All Rights Reserved
        </div>
        <div class="credits">
          <strong>
            <span>
              <Link to="/terms">Terms of service</Link>{" "}
            </span>
          </strong>{" "}
          <strong>
            <span style={{ marginLeft: 10, marginRight: 10 }}></span>
          </strong>{" "}
          <strong>
            <span>
              <Link to="/privacy">Privacy policy</Link>
            </span>
          </strong>
        </div>
      </div>
    </footer>
  );
}
