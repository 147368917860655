import React from "react";
import "./terms.css";
import Logo from "../components/assets/img/logo.png";
import { Link } from "react-router-dom";
export default function NotFound() {
  return (
    <>
      <div>
        <div className="py-5 bg-light service-25" style={{ height: "80vh" }}>
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-md-12  mb-3">
                <img src={Logo} className="img-fluid" alt="logo" style={{ width: 150 }} />
              </div>
              <div className="col-md-12 ">
                <h1 className="my-3">Not Found</h1>
                <hr />

                <div className="col-md-12 mt-3 text-center">
                  <Link to="/" className="btn btn-secondary btn-md border-0">
                    <span>Go To Homepage</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
