import React from "react";
import "./terms.css";
import Logo from "../components/assets/img/logo.png";
import { Link } from "react-router-dom";
export default function TermsOfUse() {
  return (
    <>
      <div>
        <div className="py-5 bg-light service-25" style={{ height: "80vh" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12  mb-3">
                <img src={Logo} className="img-fluid" alt="logo" style={{ width: 150 }} />
              </div>
              <div className="col-md-12 ">
                <h1 className="my-3">Terms of Use</h1>
                <hr />
                <p className="subtitle font-weight-light">Last updated "Last updated: Feb 02, 2023."</p>
                <h5 className="subtitle font-weight-light">
                  The use of this application is subject to the{" "}
                  <u>
                    <Link
                      to="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                      className="anchor-black"
                    >
                      <b> Apple Licensed Application End User License</b>
                    </Link>
                  </u>{" "}
                  (the “EULA”), which you agree to when using it.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
